<template>
        <header>
   <!-- Jumbotron -->
    <div id="intro" class="p-5 text-center bg-light">
      <h1 class="mb-0 h4">GET IN TOUCH</h1>
    </div>
    <!-- Jumbotron -->
  </header>
         <div class="container">
          <div class="row justify-content-center">
            <div>
              <form class="bg-white rounded shadow-5-strong p-5" action="../assets/email/email.php">
                
                <!-- Password input -->
                <div class="form-outline mb-4">
                  <input type="text" id="name" name="name" class="form-control" />
                  <label class="form-label" for="name">Name</label>
                </div>

                <!-- Email input -->
                <div class="form-outline mb-4">
                  <input type="email" id="email" name="email" class="form-control" />
                  <label class="form-label" for="email">Email address</label>
                </div>

                <!-- Password input -->
                <div class="form-outline mb-4">
                  <input type="text" id="subject" name="subject" class="form-control" />
                  <label class="form-label" for="subject">Subject</label>
                </div>

                <!-- Password input -->
                <div class="form-outline mb-4">
                  <textarea type="text" id="message" name="message" class="form-control" />
                  <label class="form-label" for="message">Message</label>
                </div>

                

                <!-- Submit button -->
                <button type="submit" name="submit" class="btn btn-primary btn-block">SEND</button>
              </form>
            </div>
          </div>
        </div>
</template>

<script>
  
  export default {
    name: 'ContactForm',
    components: {
      
    }
  }
</script>